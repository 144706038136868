<template>
    <div class="surface-card p-6 border-1 surface-border">
        <data-table-view
            endpoint="companies"
            headline="Dienstleister / Anbieter"
            :fixedQueryParams="{isProvider: true}"
        >
            <template slot="actions">
                <router-link to="/providers/create">
                    <Button
                        label="Neuen Dienstleister erstellen"
                        class="p-button-primary p-button-sm"
                    />
                </router-link>
            </template>
            <Column field="name" header="Name" sortable>
                <template #body="slotProps">
                    {{slotProps.data.name}}
                    <template v-if="slotProps.data.url">
                        <br>
                        <a :href="slotProps.data.url" target="_blank">
                            {{slotProps.data.url}}
                        </a>
                    </template>
                </template>
            </Column>
            <Column field="zip" header="Standort" sortable>
                <template #body="slotProps">
                    {{slotProps.data.zip}}
                    {{slotProps.data.city}}
                </template>
            </Column>
            <Column field="contactPerson" header="Ansprechpartner" sortable>
                <template #body="slotProps">
                    <template v-if="slotProps.data.mainContactPerson">
                        {{slotProps.data.mainContactPerson.firstName}}
                        {{slotProps.data.mainContactPerson.lastName}}
                    </template>
                </template>
            </Column>

            <Column header="Aktiv">
                <template #body="slotProps">
                    <i class="pi"
                       :class="{
                            'true-icon pi-check-circle': slotProps.data.active,
                        }"
                    ></i>
                </template>
            </Column>
            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <router-link
                        :to="'/providers/' + slotProps.data.id"
                        v-if="slotProps.data.userCanUpdate"
                    >
                        <Button
                            label="Anschauen"
                            class="p-button-secondary p-button-sm cursor-pointer"
                        />
                    </router-link>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";

    export default {
        components: {
            InputText,
            Button,
            DataTableView,
            Column
        },
        methods: {}
    }
</script>
